
const nav =
    { link: "https://www.etsy.com/shop/BaleiaVulcanica", text: "OnlineShop" }
    ;



export default function Button({ children }: any) {
    return (
        <div className="flex justify-center">
            <div className="border-normal bg-white py-2 px-6 rounded-lg flex justify-center align-middle content-center flex-nowrap">
                <a
                    href={nav.link}
                    className=""
                >{children}</a>
            </div>
        </div>
    )
}
