import { t } from "i18next";

const ShopButton = () => {
  return (
    <div className="flex mr-12">
      <a
        href="https://www.etsy.com/shop/BaleiaVulcanica#items"
        className="border-[0.1rem] border-black float-right Wrapper bg-pant_GrayLilac mr-5"
        target="_blank"
        rel="noreferrer"
      >
        <p className="TextDisplay">{t("NavBar_up_shop-button")}</p>
      </a>
    </div>
  );
};

export default ShopButton;
