import { t } from "i18next";

function verticalHeader() {
  return (
    <h4 className="bg-kraftThree uppercase Section2 fixed -left-[0.1rem] top-[3.95rem] text-sm mini:text-base border-r-normal pt-8 mini:pt-8 h-full pb-6 px-3">
      <div className="tracking-widest">{t("NavBar_vertical_text")}</div>
    </h4>
  );
}

export default verticalHeader;
