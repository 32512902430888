import Title from "../../Components/Title";
import { ReactElement, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const emailSuccessMessage = (): any => {
    return <>{t("Contact_send_done_text")}</>;
  };
  const emailFailedMessage = (): any => {
    return <>{t("Contact_send_failed_text")}</>;
  };
  const form = useRef<HTMLFormElement>(null);
  const sendEmail = (e: any) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          "service_e585w2m",
          "template_xjkw2qr",
          form.current,
          "1ZGhdREHblTM6WCef"
        )
        .then(
          (result) => {
            console.log(result.text);
            setMessage(emailSuccessMessage());
            if (form.current) {
              form.current.reset();
            }
          },
          (error) => {
            console.log(error.text);
            setMessage(emailFailedMessage());
          }
        );
    }
  };
  return (
    <div
      id="contact"
      className="h-full w-full overflow-hidden border-t-normal border-r-normal pl-16 sm:pr-4 sm:pt-5 flex flex-col justify-evenly ContactCss"
    >
      <Title title={t("Contact_title-categorie")} />
      <form ref={form} onSubmit={sendEmail}>
        <div className="flex w-100 flex-col w-3/4 mx-auto md:w-1/2 gap-5">
          <div className="flex flex-col">
            <div className="font-baleia_Typewritter text-xl">
              {t("Contact_name_text")}
            </div>
            <input type="text" placeholder="Name" name="from_name" />
          </div>
          <div className="flex flex-col">
            <div className="font-baleia_Typewritter text-xl">
              {t("Contact_email_text")}
            </div>
            <input type="email" placeholder="Email address" name="user_email" />
          </div>
          <div className="flex flex-col">
            <div className="font-baleia_Typewritter text-lg">
              {t("Contact_message_text")}
            </div>
            <textarea
              name="message"
              placeholder="Tape your message here"
              className="h-[8rem] sm:h-[16rem]"
            />
          </div>
          <div className="border-normal bg-white py-2 px-6 rounded-lg flex justify-center align-middle content-center flex-nowrap">
            <button type="submit">{t("Contact_send_buton")}</button>
          </div>
          {message && (
            <div className="bg-pant_PaleBlue py-2 px-4 rounded-lg flex justify-center align-middle content-center flex-nowrap">
              {message}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Contact;
