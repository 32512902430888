import lifestyle_1 from "../../../../assets/Categories/Lifestyle/lifestyle_1.jpg";
import PictureWithBorder from "../../Components/Image";
import Title from "../../Components/Title";
import Button from "../../Components/Button";
import { Text } from "../../Components/Text";
import { useBreakpoint } from "../../../../hooks/UseBreakpoint";
import { useTranslation } from "react-i18next";

const LifeStyle = () => {
  const { t } = useTranslation();
  const isBreakpoint = useBreakpoint();
  return (
    <>
      <div
        id="decoration"
        className="h-full bg-pant_Sand overflow-hidden border-normal pl-16 pr-4 pt-3 flex flex-col justify-evenly"
      >
        <Title title={t("Lifestyle_title-categorie")} />
        <Text>{t("Lifestyle_explanation")}</Text>
        {(isBreakpoint === "xs" ||
          isBreakpoint === "sm" ||
          isBreakpoint === "md") && (
          <PictureWithBorder src={lifestyle_1} height="9rem" margin="0rem" />
        )}
        {(isBreakpoint === "lg" ||
          isBreakpoint === "xl" ||
          isBreakpoint === "xxl" ||
          isBreakpoint === "xxxl") && (
          <PictureWithBorder
            src={lifestyle_1}
            height="19rem"
            width="45rem"
            margin="0 auto"
          />
        )}
        <Button>{t("Lifestyle_buton")}</Button>
      </div>
    </>
  );
};

export default LifeStyle;
