import { useTranslation } from "react-i18next";

export function PresentationMobile() {
  const { t } = useTranslation();
  return (
    <div className="miniHeight:mt-2 flex flex-col miniHeight:text-xs text-sm">
      <div className="flex justify-center">{t("AboutUs_presentation")}</div>
      <div className="my-2 font-semibold"> {t("AboutUs_subtitle")}</div>
      <div className="justify-end"> {t("AboutUs_purpose")}</div>
    </div>
  );
}

export function PresentationDesktop() {
  const { t } = useTranslation();
  return (
    <div className="miniHeight:mt-2 flex flex-col m-auto">
      <div className="mb-8">{t("AboutUs_presentation")}</div>
      <div className="mb-6 font-semibold">{t("AboutUs_subtitle")}</div>
      <div className="justify-end"> {t("AboutUs_purpose")}</div>
    </div>
  );
}
