import { useTranslation } from "react-i18next";
import pt from "../assets/Flat/pt.png";
import fr from "../assets/Flat/fr.png";
import en from "../assets/Flat/uk.png";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { MdClose, MdOutlineTranslate } from "react-icons/md";
import { useBreakpoint } from "../hooks/UseBreakpoint";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "rgb(229 204 174)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2.5,
};

export function Language() {
  const { i18n } = useTranslation(["home", "main"]);
  const isBreakpoint = useBreakpoint();
  const changeLanguage = (lng: any): any => {
    i18n.changeLanguage(lng);
  };

  const selectLang = (
    <div>
      {(() => {
        if (i18n.language === "en") {
          return <img src={en} alt="en" className="h-5 w-5 mr-3" />;
        } else if (i18n.language === "fr") {
          return <img src={fr} alt="fr" className="h-5 w-5 mr-3" />;
        } else {
          return <img src={pt} alt="pt" className="h-5 w-5 mr-3" />;
        }
      })()}
    </div>
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <button onClick={handleOpen} className="flex justify-center my-auto">
        {selectLang}
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div className="flex justify-end">
            <button type="button" onClick={handleClose}>
              {(isBreakpoint === "xs" ||
                isBreakpoint === "sm" ||
                isBreakpoint === "md") && (
                <MdClose size="1.5rem" className="mb-3" />
              )}
              {(isBreakpoint === "lg" ||
                isBreakpoint === "xl" ||
                isBreakpoint === "xxl" ||
                isBreakpoint === "xxxl") && <MdClose size="2.5rem" />}
            </button>
          </div>

          <div className="flex flex-col justify-between gap-5">
            <button
              type="button"
              onClick={() => changeLanguage("pt")}
              className="flex"
            >
              <img src={pt} alt="pt" className="h-5 w-5 mr-3" />
              <span
                className="font-baleia_Typewritter text-lg"
                onClick={handleClose}
              >
                Português
              </span>
            </button>
            <button
              type="button"
              className="flex"
              onClick={() => changeLanguage("en")}
            >
              <img src={en} alt="en" className="h-5 w-5 mr-3" />
              <span
                className="font-baleia_Typewritter text-lg"
                onClick={handleClose}
              >
                English
              </span>
            </button>

            <button
              type="button"
              className="flex"
              onClick={() => changeLanguage("fr")}
            >
              <img src={fr} alt="fr" className="h-5 w-5 mr-3" />
              <span
                className="font-baleia_Typewritter text-lg"
                onClick={handleClose}
              >
                Français
              </span>
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
