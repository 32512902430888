import {
  PresentationMobile,
  PresentationDesktop,
} from "./Components/presentation";
import VerticalHeader from "./Components/verticalHeader";
import { useBreakpoint } from "../../../../hooks/UseBreakpoint";
import bg_homePage from "../../../../assets/HomePage/background_1.jpg";

const HomePage = () => {
  const isBreakpoint = useBreakpoint();

  return (
    <div
      id="home"
      className="flex flex-col relative border-normal border-t-opacity-0 h-full bg-pant_Filed overflow-hidden"
    >
      <VerticalHeader />
      {isBreakpoint === "xs" && (
        <div className="flex flex-col gap-2 h-full mt-16">
          <img
            src={bg_homePage}
            alt="vue de la boutique"
            className="object-cover xl:border-normal w-full miniHeight:max-h-[20%] max-h-[50%]"
          />
          <div className="ml-16 mr-4 flex">
            <PresentationMobile />
          </div>
        </div>
      )}
      {isBreakpoint === "sm" && (
        <div className="flex flex-col gap-4 h-full mt-16">
          <img
            src={bg_homePage}
            alt="vue de la boutique"
            className="object-cover xl:border-normal w-full miniHeight:max-h-[30%] max-h-[50%]"
          />
          <div className="ml-16 mr-4 flex mt-9 miniHeight:mt-1">
            <PresentationMobile />
          </div>
        </div>
      )}
      {isBreakpoint === "md" && (
        <div className="flex flex-col gap-4 h-full mt-16">
          <img
            src={bg_homePage}
            alt="vue de la boutique"
            className="object-cover max-h-[50%]"
          />

          <div className="ml-16 mr-4 flex mt-12 miniHeight:mt-2">
            <PresentationMobile />
          </div>
        </div>
      )}
      {isBreakpoint === "lg" && (
        <div className="flex my-auto content-center align-middle justify-center">
          <img
            src={bg_homePage}
            alt="vue de la boutique"
            className="object-cover miniHeightPlus:max-w-[25%] miniHeight:max-h-[50%] flex max-w-[18rem] border-normal ml-24 my-auto"
          />
          <div className="ml-16 mr-4 flex my-auto">
            <PresentationDesktop />
          </div>
        </div>
      )}
      {isBreakpoint === "xl" && (
        <div className="flex my-auto content-center align-middle justify-center">
          <img
            src={bg_homePage}
            alt="vue de la boutique"
            className="object-cover flex max-h-[33rem] miniHeight:max-h-[50%] miniHeightPlus:max-w-[20%] max-w-[33rem] border-normal ml-24 my-auto"
          />
          <div className="ml-16 mr-4 flex my-auto">
            <PresentationDesktop />
          </div>
        </div>
      )}
      {isBreakpoint === "xxl" && (
        <div className="flex my-auto content-center align-middle justify-center">
          <img
            src={bg_homePage}
            alt="vue de la boutique"
            className="flex border-normal ml-24 my-auto object-cover miniHeight:max-h-[50%] miniHeightPlus:max-w-[16%] max-h-[36rem] max-w-[43rem]"
          />
          <div className="ml-16 mr-4 flex my-auto">
            <PresentationDesktop />
          </div>
        </div>
      )}
      {isBreakpoint === "xxxl" && (
        <div className="flex my-auto content-center align-middle justify-center">
          <img
            src={bg_homePage}
            alt="vue de la boutique"
            className="object-cover flex max-h-[40rem] max-w-[43rem] miniHeight:max-h-[50%] miniHeightPlus:max-w-[16%] border-normal ml-24 my-auto"
          />
          <div className="ml-16 mr-4 flex my-auto">
            <PresentationDesktop />
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
