import HomePage from "./Main/Categories/HomePage/index";
import ZeroWaste from "./Main/Categories/ZeroWaste";
import GraphicArt from "./Main/Categories/GraphicArt";
import LifeStyle from "./Main/Categories/LifeStyle";
import AboutMe from "./Main/Categories/AboutMe";
import Contact from "./Main/Categories/Contact";
import Footer from "./Footer";
import Souvenirs from "./Main/Categories/Souvenirs";
import Header from "./Header";

const Main = () => {
  return (
    <div className="h-screen font-display">
      <Header />
      <HomePage />
      <ZeroWaste />
      <GraphicArt />
      <Souvenirs />
      <LifeStyle />
      <AboutMe />
      <Contact />
      <Footer />
    </div>
  );
};

export default Main;

//<div className="fixed inset-0 bg-black" onClick={blur}>
