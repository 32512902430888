import wallart_1 from "../../../../assets/Categories/WallArt/wallart_1.png";
import wallart_2 from "../../../../assets/Categories/WallArt/wallart_2.png";
import wallart_3 from "../../../../assets/Categories/WallArt/wallart_3.png";
import wallart_4 from "../../../../assets/Categories/WallArt/wallart_4.png";
import { useBreakpoint } from "../../../../hooks/UseBreakpoint";
import Title from "../../Components/Title";
import { Text } from "../../Components/Text";
import Button from "../../Components/Button";
import PictureWithBorder from "../../Components/Image";
import { useTranslation } from "react-i18next";

const CatGraphicArt = () => {
  const isBreakpoint = useBreakpoint();
  const { t } = useTranslation();
  return (
    <>
      <div
        id="wall-art"
        className="h-full bg-pant_Sand overflow-hidden border-t-normal border-r-normal border-x-normal pl-16 pr-4 pt-9 flex flex-col justify-evenly"
      >
        <Title title={t("GraphicArt_title-categorie")} />
        <Text>{t("GraphicArt_explanation")}</Text>
        {isBreakpoint === "xs" && (
          <div className="flex gap-2 flex-wrap">
            <PictureWithBorder
              src={wallart_1}
              height="4rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={wallart_2}
              height="4rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={wallart_3}
              height="4rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={wallart_4}
              height="4rem"
              width="auto"
              margin="auto"
            />
          </div>
        )}
        {isBreakpoint === "sm" && (
          <div className="flex gap-2 flex-wrap">
            <PictureWithBorder
              src={wallart_1}
              height="8rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={wallart_2}
              height="8rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={wallart_3}
              height="8rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={wallart_4}
              height="8rem"
              width="auto"
              margin="auto"
            />
          </div>
        )}
        {isBreakpoint === "md" && (
          <div className="flex gap-2 flex-wrap">
            <PictureWithBorder
              src={wallart_1}
              height="12rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={wallart_2}
              height="12rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={wallart_3}
              height="12rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={wallart_4}
              height="12rem"
              width="auto"
              margin="auto"
            />
          </div>
        )}

        {(isBreakpoint === "lg" ||
          isBreakpoint === "xl" ||
          isBreakpoint === "xxl" ||
          isBreakpoint === "xxxl") && (
          <div className="flex gap-9 flex-wrap justify-center">
            <PictureWithBorder
              src={wallart_1}
              height="18rem"
              width="auto"
              margin="auto 0"
            />
            <PictureWithBorder
              src={wallart_2}
              height="18rem"
              width="auto"
              margin="auto 0"
            />
            <PictureWithBorder
              src={wallart_3}
              height="18rem"
              width="auto"
              margin="auto 0"
            />
            <PictureWithBorder
              src={wallart_4}
              height="18rem"
              width="auto"
              margin="auto 0"
            />
          </div>
        )}

        <Button>{t("GraphicArt_buton")}</Button>
      </div>
    </>
  );
};

export default CatGraphicArt;
