import { useTranslation } from "react-i18next";

export function PresentationMobile() {
  const { t } = useTranslation();
  return (
    <div className="miniHeight:mt-2 flex flex-col m-auto text-sm">
      <div className="font-semibold">
        {t("HomePage_title_welcome-one")}
        <span className="font-baleia_Typewritter">
          {t("HomePage_title_welcome-two")}
        </span>
      </div>
      <div className="mb-1">{t("HomePage_text")}</div>
      <div className="flex justify-end">{t("HomePage_text_thank-you")}</div>
    </div>
  );
}

export function PresentationDesktop() {
  const { t } = useTranslation();
  return (
    <div className="miniHeight:mt-2 flex flex-col m-auto">
      <div className="font-semibold mb-8">
        {t("HomePage_title_welcome-one")}
        <span className="font-baleia_Typewritter">
          {t("HomePage_title_welcome-two")}
        </span>
      </div>
      <div className="mb-6">{t("HomePage_text")}</div>
      <div className="flex justify-end">{t("HomePage_text_thank-you")}</div>
    </div>
  );
}
