import React from "react";
import ShopButton from "./ShopButton";
import Title from "./Logo";
import { Language } from "../Language";
import Menu from "./Menu";
import { useBreakpoint } from "../../hooks/UseBreakpoint";

const Header: React.FC = () => {
  const isBreakpoint = useBreakpoint();
  return (
    <div
      className="border-b-normal
      backdrop-blur-sm fixed w-full h-16 z-50 justify-between items-center flex"
    >
      <Menu />
      <Title />
      <div className="flex mr-5">
        {(isBreakpoint === "xs" ||
          isBreakpoint === "sm" ||
          isBreakpoint === "md") &&
          ""}
        {(isBreakpoint === "lg" ||
          isBreakpoint === "xl" ||
          isBreakpoint === "xxl" ||
          isBreakpoint === "xxxl") && <ShopButton />}

        <Language />
      </div>
    </div>
  );
};

export default Header;
