import About_Me from "../../../../../assets/AboutMe/About_Me.jpg";

const Image = () => {
  return (
    // <div className="flex max-h-[15rem] mini:max-h-[22rem] miniHeight:max-h-[18rem] sm:max-h-[32rem] md:max-h-[35rem] lg:h-[65rem] lg:border-normal">
    <div className="flex border-normal max-h-[5rem] mini:max-h-[22rem] miniHeight:max-h-[10rem] miniHeightPlus:max-h-[10rem] md:max-h-[35rem] lg:h-[65rem] lg:border-normal">
      <img
        src={About_Me}
        alt="vue de la boutique"
        className="object-cover xl:border-normal"
      />
    </div>
  );
};

export default Image;
