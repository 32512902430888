import { useState, useEffect } from "react";

export function useBreakpoint(): string {
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 375) {
        setBreakpoint("xs");
      } else if (window.innerWidth < 640) {
        setBreakpoint("sm");
      } else if (window.innerWidth < 768) {
        setBreakpoint("md");
      } else if (window.innerWidth < 1024) {
        setBreakpoint("lg");
      } else if (window.innerWidth < 1280) {
        setBreakpoint("xl");
      } else if (window.innerWidth < 1536) {
        setBreakpoint("xxl");
      } else {
        setBreakpoint("xxxl");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially to set the breakpoint based on the initial window size

    return () => window.removeEventListener("resize", handleResize); // Clean up the event listener when the component unmounts
  }, []);

  return breakpoint;
}
