import { useBreakpoint } from "../../../../hooks/UseBreakpoint";
import { Text } from "../../Components/Text";
import Title from "../../Components/Title";
import Button from "../../Components/Button";
import PictureWithBorder from "../../Components/Image";
import souvenir_1 from "../../../../assets/Categories/Souvenirs/souvenir_1.jpg";
import souvenir_2 from "../../../../assets/Categories/Souvenirs/souvenir_2.jpg";
import { useTranslation } from "react-i18next";

function Souvenirs() {
  const isBreakpoint = useBreakpoint();
  const { t } = useTranslation();
  return (
    <>
      {/* {isBreakpoint === "xs" && "sm" && ( */}
      <div
        id="souvenirs"
        className="h-full overflow-hidden bg-pant_PaleBlue border-t-normal border-r-normal pl-16 pr-4  flex flex-col justify-evenly"
      >
        <Title title={t("Souvenirs_title-categorie")} />
        <Text>{t("Souvenirs_explanation")}</Text>
        {isBreakpoint === "xs" && (
          <div className="flex gap-2 flex-wrap">
            <PictureWithBorder
              src={souvenir_1}
              height="8rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={souvenir_2}
              height="8rem"
              width="auto"
              margin="auto"
            />
          </div>
        )}
        {isBreakpoint === "sm" && (
          <div className="flex gap-2 flex-wrap">
            <PictureWithBorder
              src={souvenir_1}
              height="11rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={souvenir_2}
              height="11rem"
              width="auto"
              margin="auto"
            />
          </div>
        )}
        {isBreakpoint === "md" && (
          <div className="flex gap-2 flex-wrap">
            <PictureWithBorder
              src={souvenir_1}
              height="19rem"
              width="auto"
              margin="auto"
            />
            <PictureWithBorder
              src={souvenir_2}
              height="19rem"
              width="auto"
              margin="auto"
            />
          </div>
        )}

        {(isBreakpoint === "lg" ||
          isBreakpoint === "xl" ||
          isBreakpoint === "xxl" ||
          isBreakpoint === "xxxl") && (
          <div className="flex gap-9 flex-wrap justify-center">
            <PictureWithBorder
              src={souvenir_1}
              height="28rem"
              width="auto"
              margin="auto 0"
            />
            <PictureWithBorder
              src={souvenir_2}
              height="28rem"
              width="auto"
              margin="auto 0"
            />
          </div>
        )}
        <Button>{t("Souvenirs_buton")}</Button>
      </div>
    </>
  );
}

export default Souvenirs;
