import { ReactComponentElement, useState } from "react";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { useBreakpoint } from "../../hooks/UseBreakpoint";
import { useTranslation } from "react-i18next";

const Menu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation();

  const navigationPages = [
    { link: "#home", text: t("Menu_home") },
    { link: "#zero-waste", text: t("Menu_zero-waste") },
    { link: "#wall-art", text: t("Menu_graphic-art") },
    { link: "#souvenirs", text: t("Menu_souvenirs") },
    { link: "#decoration", text: t("Menu_lifestyle") },
    { link: "#about-me", text: t("Menu_about-us") },
    { link: "#contact", text: t("Menu_contact") },
  ];

  const navigationLinks = [
    { link: "https://www.etsy.com/shop/BaleiaVulcanica", text: "OnlineShop" },
    {
      link: "https://www.instagram.com/baleiavulcanica/?igshid=YmMyMTA2M2Y%3D",
      text: "Instagram",
    },
    {
      link: "https://fr.tripadvisor.be/Attraction_Review-g189167-d26266977-Reviews-Baleia_Vulcanica-Funchal_Madeira_Madeira_Islands.html",
      text: "Tripadvisor",
    },
  ];

  function MenuOpen(): JSX.Element {
    return (
      <nav className="bg-pant_GrayLilac absolute top-0 border-b-big pt-2 w-full mt-16 left-0 p-5">
        <ul className="flex flex-row  flex-wrap gap-4">
          <h4 className="text-start font-bold text-xl w-full mb-1">Menu</h4>
          {navigationPages.map((nav) => (
            <li key={nav.text} className="flex flex-wrap gap-4">
              <a
                href={nav.link}
                onClick={toggle}
                className="border-normal bg-white hover:bg-pant_PalePink rounded-lg p-1"
              >
                #{nav.text}
              </a>
            </li>
          ))}
          <h4 className="text-start font-bold text-xl w-full mb-1">Links</h4>
          {navigationLinks.map((nav) => (
            <li key={nav.text} className="flex flex-wrap gap-4">
              <a
                href={nav.link}
                onClick={toggle}
                className="border-normal border-white bg-black hover:bg-pant_PaleGreen text-white rounded-lg p-1"
              >
                {nav.text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  }

  return (
    <button onClick={toggle}>
      {isOpen ? (
        <div className="ml-3">
          <MdClose size="2.5rem" />
          <MenuOpen />
        </div>
      ) : (
        <div className="ml-3">
          <FiMenu size="2.5rem" />
        </div>
      )}
    </button>
  );
};

export default Menu;
