import instagram from "../../assets/Logo/Logo_others/instagram.png";
import etsy from "../../assets/Logo/Logo_others/etsyLogo.png";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div className="h-28 bg-[#EDCDC1] border-[0.1rem] pl-6 border-black flex m-auto justify-between flex-col">
      <div className="font-normal mx-auto pt-4 ">
        Baleia Vulcânica® - {year}
      </div>
      <div className="mx-auto flex flex-row mb-4 gap-5">
        <a
          href={"https://www.etsy.com/shop/BaleiaVulcanica"}
          className=" hover:bg-pant_PalePink rounded-lg p-1"
        >
          <img src={etsy} className="h-8" />
        </a>
        <a
          href={
            "https://www.instagram.com/baleiavulcanica/?igshid=YmMyMTA2M2Y%3D"
          }
          className=" hover:bg-pant_PalePink rounded-lg p-1"
        >
          <img src={instagram} className="h-8" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
