import { useBreakpoint } from "../../../../hooks/UseBreakpoint";
import zerowast_1 from "../../../../assets/Categories/ZeroWaste/zerowast_1.jpg";
import Title from "../../Components/Title";
import PictureWithBorder from "../../Components/Image";
import Button from "../../Components/Button";
import { Text } from "../../Components/Text";
import { useTranslation } from "react-i18next";

function ZeroWaste() {
  const isBreakpoint = useBreakpoint();
  const { t } = useTranslation();
  return (
    <>
      <div
        id="zero-waste"
        className="h-full overflow-hidden bg-pant_GrayLilac border-x-normal pl-16 pr-4 pt-10 flex flex-col justify-evenly"
      >
        <Title title={t("ZeroWaste_title-categorie")} />
        <Text>{t("ZeroWast_explanation")}</Text>
        {(isBreakpoint === "xs" ||
          isBreakpoint === "sm" ||
          isBreakpoint === "md") && (
          <PictureWithBorder src={zerowast_1} height="9rem" margin="0rem" />
        )}
        {(isBreakpoint === "lg" ||
          isBreakpoint === "xl" ||
          isBreakpoint === "xxl" ||
          isBreakpoint === "xxxl") && (
          <PictureWithBorder
            src={zerowast_1}
            height="19rem"
            width="45rem"
            margin="0 auto"
          />
        )}
        <Button>{t("ZeroWast_buton")}</Button>
      </div>
    </>
  );
}

export default ZeroWaste;
