import { useBreakpoint } from "../../../../hooks/UseBreakpoint";
import Title from "../../Components/Title";
import Image from "./Components/image";
import {
  PresentationMobile,
  PresentationDesktop,
} from "./Components/presentation";
import About_Me from "../../../../assets/AboutMe/About_Me.jpg";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const isBreakpoint = useBreakpoint();
  const { t } = useTranslation();
  return (
    <>
      <div
        id="about-me"
        className="h-full bg-pant_GrayLilac overflow-hidden border-r-normal flex flex-col justify-evenly pl-16 pr-4 pt-5"
      >
        <Title title={t("AboutUs_title-categorie")} />

        {isBreakpoint === "xs" && (
          <div className="flex flex-col gap-4 h-full mt-2">
            <Image />
            <PresentationMobile />
          </div>
        )}
        {isBreakpoint === "sm" && (
          <div className="flex flex-col gap-4 h-full mt-16">
            <Image />
            <PresentationMobile />
          </div>
        )}
        {isBreakpoint === "md" && (
          <div className="flex flex-col gap-4 h-full mt-16">
            <div className="flex max-h-[12rem] border-normal">
              <img
                src={About_Me}
                alt="vue de la boutique"
                className="object-cover"
              />
            </div>
            <PresentationMobile />
          </div>
        )}
        {isBreakpoint === "lg" && (
          <div className="flex my-auto content-center align-middle justify-center">
            <div className="flex h-[32rem] w-[132rem] border-normal ml-24">
              <img
                src={About_Me}
                alt="vue de la boutique"
                className="object-cover"
              />
            </div>
            <div className="ml-9 mr-4 flex my-auto">
              <PresentationDesktop />
            </div>
          </div>
        )}
        {isBreakpoint === "xl" && (
          <div className="flex my-auto content-center align-middle justify-center">
            <div className="flex h-[32rem] w-[132rem] border-normal ml-24">
              <img
                src={About_Me}
                alt="vue de la boutique"
                className="object-cover"
              />
            </div>
            <div className="ml-9 mr-4 flex my-auto">
              <PresentationDesktop />
            </div>
          </div>
        )}
        {isBreakpoint === "xxl" && (
          <div className="flex my-auto content-center align-middle justify-center">
            <div className="flex h-[32rem] w-auto border-normal ml-24">
              <img
                src={About_Me}
                alt="vue de la boutique"
                className="object-cover"
              />
            </div>
            <div className="ml-9 mr-4 flex my-auto">
              <PresentationDesktop />
            </div>
          </div>
        )}
        {isBreakpoint === "xxxl" && (
          <div className="flex my-auto content-center align-middle justify-center">
            <div className="flex h-[32rem] w-auto border-normal ml-24">
              <img
                src={About_Me}
                alt="vue de la boutique"
                className="object-cover"
              />
            </div>
            <div className="ml-9 mr-4 flex my-auto">
              <PresentationDesktop />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AboutUs;
