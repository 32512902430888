import React from 'react';

interface PictureWithBorderProps {
    src: string;
    alt?: string;
    size?: string;
    height?: string;
    width?: string;
    margin?: string;
}


const PictureWithBorder: React.FC<PictureWithBorderProps> = ({ src, alt = '', height = '100%', width = '100%', margin = 'auto' }) => {
    return (
        <img
            className='border-normal object-cover'
            src={src}
            alt={alt}
            style={{ width: width, height: height, margin: margin, }}
        />
    );
};

export default PictureWithBorder;