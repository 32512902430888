import logoText from "../../assets/Logo/BaleiaVulcanica/text-logo-baleia-vulvanica.png";

const Logo = () => {
  return (
    <>
      <img src={logoText} className="z-2 w-32 sm:w-40 md:w-44 lg:w-44" alt="logo" />
    </>
  );
};

export default Logo;
